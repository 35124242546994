import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-togglebutton',
  templateUrl: './togglebutton.component.html',
  styleUrls: ['./togglebutton.component.scss']
})
export class TogglebuttonComponent implements OnInit {

  @Input() checked: boolean;
  @Output() save: EventEmitter<boolean> = new EventEmitter();
  @Input() value: any;
  @Input() rowData: any;
  @Output() toggleChanged: EventEmitter<any> = new EventEmitter();
  isActive: boolean;
  constructor() { 
  }

  ngOnInit(): void {
    this.isActive = this.rowData.isActive;
  }

  onClick() {
    this.save.emit(this.checked);
  }
  onToggle(checked: boolean): void {
    this.isActive = checked;
   this.toggleChanged.emit({ rowData: this.rowData, value: checked });
  }
}
