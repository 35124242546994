import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '../../services/Account.service';
import { Location } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loading$ = new BehaviorSubject < boolean > (false);
  loginForm: FormGroup;
  loadingLargeGroup = false;
  loginValidity: boolean = false;
  constructor(
    private router: Router,
    private accountservice: AccountService,
    private fb: FormBuilder,
    private location: Location,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.initlogin()
  }
  initlogin() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.email, Validators.required]],
      password: ["", [Validators.required]]
    });
  }

  public navigate(route: string): void {
    // this.router.navigateByUrl(route)
    // document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
  onLogin() {
    this.loginValidity = false;
    this.loadingLargeGroup = false;
    this.accountservice.login(this.loginForm.getRawValue().email, this.loginForm.getRawValue().password).subscribe(
      resp => {
        localStorage.setItem('UserContext', JSON.stringify(resp.result.payload.token.tokenString))
        localStorage.setItem('restrictedUserMenu', JSON.stringify(resp.result.payload.id))
        localStorage.setItem('currentuseremail', JSON.stringify(this.loginForm.getRawValue().email))
        if(JSON.parse(localStorage.getItem('restrictedUserMenu')) === "91e64499-2811-410a-98fb-dc8ac6a5d75b"){
          this.translate.use('pl');
          this.router.navigate(['/pages/user'])
        } else {
          this.translate.use('en');
          this.router.navigate(['/dashboard'])
        }
      },
      err => {
        this.loadingLargeGroup = false;
        this.loginValidity = true
        return;
        // this.toastr.error('Login Failed','Please Check you Em/ail or Password and try again')

      }
    )
  }

}
