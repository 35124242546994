<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" ><img src="../../../../assets/images/boshhh-logo.svg" height="40px"/></a>
    <!-- <div class="check"><img src="../../../../assets/images/logo-inverted.png" height="70px" width="70px" /></div> -->
  <!-- </div> -->
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" style="margin-left: 40px;" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <!-- <ul> -->
        <div *ngFor="let item of userMenu" (click)="handleMenuItemClick(item)"  class="pointer-cursor" >
         <b> {{ item.title | translate }}  </b>
        </div>
    </nb-action>
  </nb-actions>
</div>
