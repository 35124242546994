import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };

  constructor(
    private http: HttpClient,
    // @ts-ignore
  ) { }

  public login(email: string, password: string): Observable<any> {
    const obj = {
      email: email,
      password: password,
      isPersistent: true
    }
    return this
      .http
      .post<any>(`${environment.ApiUrl}/api/Registration/DashBoardLogin`,obj,
        this.httpOptions
      );

  }

}
