import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      {{ 'FOOTER.CREATED_BY' | translate }} <b><a href="https://boshhh.com" target="_blank">{{ 'FOOTER.LINK_TEXT' | translate }}</a></b>
    </span>
  `,
})
export class FooterComponent {
  constructor(private translate: TranslateService) {}
}
