import { Injectable } from '@angular/core'
import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http'
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { LoaderService } from '../../../services/loader.service';
@Injectable({
    providedIn: 'root'
  })
export class AuthInterceptor implements HttpInterceptor{

    constructor(private loaderService: LoaderService){

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        this.loaderService.show()
        const token = localStorage.getItem('UserContext')
        var ttoken
        if(token !== null){
            ttoken = JSON.parse(token)
        }
        let tokenizedRequest = req.clone({
            setHeaders:{
                Authorization: `Bearer ${ttoken}`
            }
        })
        return next.handle(tokenizedRequest).pipe(tap(
            (response: HttpEvent<any>) => {
                if(response instanceof HttpResponse){
                    this.loaderService.hide()
                }
              },
            (err: any) => {
            if (err instanceof HttpErrorResponse) {
                this.loaderService.hide()
                if (err.status === 401) {
                    localStorage.removeItem('UserContext')
                    if(window.location.pathname == '/auth'){

                    }
                    else{
                        window.location.href = "/auth";
                    }
                }
            }
        }) )
    }
}