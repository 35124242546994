
<div>
  <h1 id="title" class="title">Login</h1>
  <p class="sub-title">Hello! Log in with your email.</p>
  <form novalidate="" [formGroup]="loginForm" (ngSubmit)="onLogin()" aria-labelledby="title"
    class="ng-touched ng-dirty ng-valid">
    <div class="form-control-group">
      <label for="input-email" class="label">Email address:</label>
      <input nbinput="" formControlName="email" fullwidth="" name="email" id="-email" pattern=".+@.+\..+"
        placeholder="Email address" fieldsize="large" autofocus="" ng-reflect-full-width=""
        ng-reflect-field-size="large" ng-reflect-pattern=".+@.+\..+" ng-reflect-name="email" ng-reflect-status="success"
        ng-reflect-required="true"
        class="input-full-width size-large shape-rectangle nb-transition ng-touched status-success ng-dirty ng-valid"
        required="" ng-reflect-model="lewis@boshhh.com">
    </div>
    <div class="form-control-group">
      <span class="label-with-link">
        <label for="input-password" class="label">Password:</label>
        <a routerlink="../request-password" class="forgot-password caption-2"
          ng-reflect-router-link="../request-password" href="/request-password">Forgot Password?</a></span>
      <input nbinput="" fullwidth="" name="password" formControlName="password" type="password" id="input-password"
        placeholder="Password" fieldsize="large" ng-reflect-full-width="" ng-reflect-field-size="large"
        ng-reflect-name="password" ng-reflect-status="success" ng-reflect-required="true" ng-reflect-minlength="4"
        ng-reflect-maxlength="50"
        class="input-full-width size-large shape-rectangle nb-transition status-success ng-dirty ng-valid ng-touched"
        required="" minlength="4" maxlength="50" ng-reflect-model="google">
        <small [hidden]="!loginValidity" style="color: red;" class="text-start">Please check you credentials</small>
    </div>
    <button nbbutton="" fullwidth="" status="success" size="large" ng-reflect-full-width="" ng-reflect-status="success"
      ng-reflect-size="large" ng-reflect-disabled="false" aria-disabled="false" tabindex="0"
      class="appearance-filled full-width size-large shape-rectangle status-success nb-transition" size="large"
      [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="primary" nbSpinnerSize="large" nbSpinnerMessage=""> Log
      In </button>
  </form>
</div>