import {  Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  showLoader: boolean;

  constructor(private loaderService: LoaderService) {
    this.loaderService.loaderState.subscribe((state) => {
      this.showLoader = state;
    });

  }

}
